import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import { HiscoreContextProvider } from "../../contexts/hiscoreContext";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import Hiscores from "../../components/Hiscore/Hiscores";
import { SubmissionContext } from "../../contexts/submissionContext";
import Link from "@material-ui/core/Link";

interface IndividualHiscoreParams {
  category?: string;
  type?: string;
}

interface IndividualHiscoreProps
  extends RouteComponentProps<IndividualHiscoreParams> {}

const fmtMSS = (s) => (s - (s %= 60)) / 60 + (s > 9 ? ":" : ":0") + s;

const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

const specialTypes = ["overall", "community", "pets", "diary"];
const categoryToQuery = {
  points: "PointCategory_id",
  kc: "Boss_id",
  ehb: "Boss_id",
  time: "Record_id",
  skills: "Skill_id",
};

const IndividualHiscore: FunctionComponent<IndividualHiscoreProps> = ({
  match,
}) => {
  const { clan } = useContext(ClanContext);
  const { pointCategories, bosses, times, skills } = useContext(
    SubmissionContext
  );

  const { t } = useTranslation();

  // if no category or type is provided, redirect to the home page
  if (!match.params.category || !match.params.type) {
    return <Redirect to="/" />;
  }

  const categoryToLabelObject = {
    points: pointCategories,
    kc: bosses,
    ehb: bosses,
    time: times,
    skills: skills,
  };

  const categoryToValueAttribute = {
    points: (x: any) => parseInt(x.value).toLocaleString(),
    kc: (x: any) => parseInt(x.value).toLocaleString(),
    ehb: (x: any) =>
      (
        x.value /
        bosses?.find((boss) => boss?.id === parseInt(match?.params?.type))?.ehb
      ).toFixed(2),
    time: (x: any) => <Link href={x.screenshot}>{fmtMSS(x.value)}</Link>,
    skills: (x: any) => parseInt(x.value).toLocaleString(),
  };

  const categoryToSpecialValueAttribute = {
    points: (x: any) => parseInt(x.sum || x.count).toLocaleString(),
    kc: (x: any) => parseInt(x.sum).toLocaleString(),
    ehb: (x: any) =>
      parseFloat(x.sum).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    time: (x: any) => x.sum,
    skills: (x: any) => parseInt(x.value).toLocaleString(),
  };

  const categoryToUsernameAttribute = {
    points: (x: any) => x.member && x.member.username,
    kc: (x: any) => x.member && x.member.username,
    ehb: (x: any) => x.member && x.member.username,
    time: (x: any) =>
      x.members &&
      x.members
        .map((member: any) => member.username)
        .sort()
        .join(", "),
    skills: (x: any) => x.member && x.member.username,
  };

  const categoryToLabelAttribute = {
    points: `${capitalizeFirstLetter(
      match.params.category
    )} - ${capitalizeFirstLetter(
      categoryToLabelObject[match?.params?.category].find(
        (x) => x.id === parseInt(match?.params?.type)
      ) &&
        categoryToLabelObject[match?.params?.category].find(
          (x) => x.id === parseInt(match?.params?.type)
        ).name
    )}`,
    kc: `${capitalizeFirstLetter(
      match.params.category
    )} - ${capitalizeFirstLetter(
      categoryToLabelObject[match?.params?.category].find(
        (x) => x.id === parseInt(match?.params?.type)
      ) &&
        categoryToLabelObject[match?.params?.category].find(
          (x) => x.id === parseInt(match?.params?.type)
        ).name
    )}`,
    ehb: `${capitalizeFirstLetter(
      match.params.category
    )} - ${capitalizeFirstLetter(
      categoryToLabelObject[match?.params?.category].find(
        (x) => x.id === parseInt(match?.params?.type)
      ) &&
        categoryToLabelObject[match?.params?.category].find(
          (x) => x.id === parseInt(match?.params?.type)
        ).name
    )}`,
    time: `${capitalizeFirstLetter(match.params.category)}`,
    skills: `${capitalizeFirstLetter(
      match.params.category
    )} - ${capitalizeFirstLetter(
      categoryToLabelObject[match?.params?.category].find(
        (x) => x.id === parseInt(match?.params?.type)
      ) &&
        categoryToLabelObject[match?.params?.category].find(
          (x) => x.id === parseInt(match?.params?.type)
        ).name
    )}`,
  };

  // check if the category is kc or ehb, and if so, set the image to the boss image
  let image = clan.clanBackgroundImage;

  if (match.params.category === "kc" || match.params.category === "ehb") {
    image = bosses?.find((boss) => boss?.id === parseInt(match?.params?.type))
      ?.artwork;
  } else if (match.params.category === "time") {
    image = bosses?.find(
      (boss) =>
        boss.id ===
        times?.find((time) => time?.id === parseInt(match?.params?.type))
          ?.Boss_id
    )?.artwork;
  }

  if (specialTypes.includes(match.params.type)) {
    return (
      <Layout title={t("hiscores.pageTitle")} background={image}>
        <HiscoreContextProvider>
          <Hiscores
            key="community"
            label={`${capitalizeFirstLetter(
              match.params.category
            )} - ${capitalizeFirstLetter(match.params.type)}`}
            valueLabel={capitalizeFirstLetter(match.params.type)}
            valueAttribute={
              categoryToSpecialValueAttribute[match.params.category]
            }
            usernameAttribute={(x: any) => x.member && x.member.username}
            points={
              match.params.category === "points" &&
              match.params.type !== "pets" &&
              match.params.type !== "diary"
            }
            overall={match.params.type === "overall"}
            community={match.params.type === "community"}
            pets={match.params.type === "pets"}
            diaries={match.params.type === "diary"}
            ehb={match.params.category === "ehb"}
            times={match.params.category === "time"}
            skill={match.params.category === "skills"}
          />
        </HiscoreContextProvider>
      </Layout>
    );
  } else {
    return (
      <Layout title={t("hiscores.pageTitle")} background={image}>
        <HiscoreContextProvider>
          <Hiscores
            label={categoryToLabelAttribute[match.params.category]}
            valueLabel={capitalizeFirstLetter(match.params.category)}
            query={{
              [categoryToQuery[match?.params?.category]]: match.params.type,
            }}
            valueAttribute={categoryToValueAttribute[match?.params?.category]}
            usernameAttribute={
              categoryToUsernameAttribute[match?.params?.category]
            }
            skill={match.params.category === "skills"}
            times={match.params.category === "time"}
            points={match.params.category === "points"}
            page={match.params.category === "time" ? 5 : 10}
          />
        </HiscoreContextProvider>
      </Layout>
    );
  }
};

export default withRouter(IndividualHiscore);
