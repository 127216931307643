import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Social from "./Social";
import Member from "./Member";
import Admin from "./Admin";
import Clans from "./Clans";
import Hiscores from "./Hiscore";
import Submissions from "./Submissions";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { ThemeContext } from "../../contexts/themeContext";
import { transition, drawerWidth } from "../../assets/jss/global";

export default function Drawers({ open, handleDrawerToggle }) {
  const { user } = useContext(UserContext);
  const { clan } = useContext(ClanContext);
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";
  const isMentorStaff =
    user && user.teacherPrograms && user.teacherPrograms.length > 0;
  const { mode } = useContext(ThemeContext);

  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      border: "none",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      backgroundColor: mode === "dark" ? "#121212" : "#ededed",
      width: drawerWidth,
      height: "100vh",
      visibility: "visible",
      marginTop: "53px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "52px",
      },
      overflowY: "visible",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      paddingBottom: "40px",
      boxShadow:
        "0 0px 0px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      ...transition,
    },
    appResponsive: {
      margin: "20px 10px",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            className={classes.appResponsive}
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {!process.env.REACT_APP_STANDALONE_ID && !clan.id && (
              <Clans baseUrl={baseUrl} />
            )}

            {clan.id && <Member baseUrl={baseUrl} />}

            {user && user.id && user.Clan_id === clan.id && (
              <Submissions baseUrl={baseUrl} />
            )}
            {clan.id && <Hiscores baseUrl={baseUrl} />}
            {clan.id && <Social baseUrl={baseUrl} />}

            {user &&
              (user.isAdmin ||
                user.isEventStaff ||
                isMentorStaff ||
                user.isClanAdmin) &&
              user.Clan_id === clan.id && <Admin baseUrl={baseUrl} />}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            className={classes.appResponsive}
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {!process.env.REACT_APP_STANDALONE_ID && !clan.id && (
              <Clans baseUrl={baseUrl} />
            )}

            {clan.id && <Member baseUrl={baseUrl} />}

            {user && user.id && user.Clan_id === clan.id && (
              <Submissions baseUrl={baseUrl} />
            )}
            {clan.id && <Hiscores baseUrl={baseUrl} />}

            {clan.id && <Social baseUrl={baseUrl} />}

            {user &&
              (user.isAdmin ||
                user.isEventStaff ||
                isMentorStaff ||
                user.isClanAdmin) &&
              user.Clan_id === clan.id && <Admin baseUrl={baseUrl} />}
          </div>
        </Drawer>
      </Hidden>
    </>
  );
}
